@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Minecraft';
  src: url('/public/fonts/Minecraft-Regular.eot');
  src: url('/public/fonts/Minecraft-Regular.eot?#iefix') format('embedded-opentype'),
  url('/public/fonts/Minecraft-Regular.woff2') format('woff2'),
  url('/public/fonts/Minecraft-Regular.woff') format('woff'),
  url('/public/fonts/Minecraft-Regular.ttf') format('truetype'),
  url('/public/fonts/Minecraft-Regular.svg#Minecraft-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  color: red;
  font-size: 5vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Assistant', sans-serif;
}

span {
  font-family: Minecraft, sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
